import {Component, Inject} from "@angular/core";
import {
    BoardGameBasicResponse,
    LocationResponse,
    MeetupResponse,
    PlannedPlayResponse
} from "../model/responses";
import {PlannedPlayRequest} from "../model/requests";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BreakpointObserver} from "@angular/cdk/layout";
import {MeetupService} from "../service/api/meetup.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {NgIf, NgFor} from "@angular/common";
import {HeaderComponent} from "../shared/header.component";
import {SharedDialogsService} from "../service/shared-dialogs.service";
import {visibilities, Visibility} from "../model/visibility";
import {
    MAT_DIALOG_DATA,
    MatDialogActions, MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {AuthedUserService} from "../service/authed-user.service";
import {GameSearchFormComponent} from "../game/game-search-form.component";
import {BoardGameService} from "../service/api/board-game.service";
import {CurrentViewDataService} from "../service/current-view-data.service";
import {ImageComponent} from "../shared/image.component";
import {dateToString} from "../model/period";
import {getDate} from "../model/utils";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Organize Planned Play</h1>
        <mat-dialog-content>
            <form class="add-content-form column" *ngIf="!saving">
                <span *ngIf="meetup" style="margin-bottom: 8px" i18n>Meetup: {{meetup.title}}</span>
                <cb-game-search-form
                    [selectedGame]="selectedGame"
                    optional onlyBaseGames
                    (selectedGameChange)="selectGame($event)"
                ></cb-game-search-form>

                <mat-form-field style="width: 100%">
                    <mat-label i18n>Title</mat-label>
                    <input matInput [(ngModel)]="title" name="title" [required]="!selectedGame">
                </mat-form-field>
                <!--<div class="row center" style="margin: 8px 0">
                    <cb-image width="300" height="150" [imageUrl]="imageUrl" editable
                              (imageChange)="imageFile = $event">
                    </cb-image>
                </div>-->
                <mat-form-field style="width: 100%">
                    <mat-label i18n>Description</mat-label>
                    <textarea matInput cdkTextareaAutosize [(ngModel)]="description" name="description"></textarea>
                </mat-form-field>
                <div class="row center align-center" style="margin-bottom: 16px" *ngIf="!meetup">
                    <button mat-stroked-button style="width: 100%" *ngIf="location" (click)="openLocationsDialog()">
                        <mat-icon>location_on</mat-icon>
                        <span>{{ location.name }}</span>
                    </button>
                    <button mat-icon-button class="small-icon-button" *ngIf="location" (click)="location = undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-stroked-button *ngIf="!location" (click)="openLocationsDialog()"
                            style="width: 100%; height: 48px; background-color: var(--mat-app-background-color-variant)">
                        <mat-icon>not_listed_location</mat-icon>
                        <span i18n style="color: var(--secondary-text-color); font-size: 16px">Set Location</span>
                        <span *ngIf="!meetup">*</span>
                    </button>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label i18n>Date</mat-label>
                    <input matInput [matDatepicker]="picker" [value]="date"
                           readonly
                           required
                           (click)="picker.open()"
                           (focus)="picker.open()"
                           (dateChange)="date = $event.value" #dateInput>
                    <mat-datepicker-toggle matSuffix *ngIf="!date" [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-icon-button matSuffix *ngIf="date"
                            (click)="$event.stopPropagation(); date = undefined; dateInput.blur()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="fill" class="fill">
                    <mat-label i18n>Start Time</mat-label>
                    <input matInput type="time" name="startTime" [(ngModel)]="startTime" required>
                </mat-form-field>
                <mat-form-field appearance="fill" class="fill">
                    <mat-label i18n>Slots</mat-label>
                    <input matInput type="number" name="slots" required [(ngModel)]="slots">
                </mat-form-field>
                <div class="row space-between align-center">
                    <mat-form-field appearance="fill" class="fill">
                        <mat-label i18n>Visibility</mat-label>
                        <mat-select [(value)]="visibility">
                            <mat-option *ngFor="let settingType of visibilities"
                                        [value]="settingType.type">{{settingType.meetupLabel}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div class="saving column center align-center" *ngIf="saving">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-flat-button color="primary" (click)="save()"
                    [disabled]="!(selectedGame || (title && slots)) || !(meetup || (date && startTime && location)) || saving"
                    i18n>Save</button>
        </div>
    `,
    styles: [`
        .add-content-form {
            margin: 8px;
        }

        .section-separation {
            margin-bottom: 16px;
        }

        .saving {
            margin-top: 32px;
        }

        .write-post-attachment-buttons {
            margin-top: 10px;
            margin-right: 10px;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [HeaderComponent, NgIf, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatIconModule, MatDatepickerModule, MatProgressSpinnerModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, GameSearchFormComponent, ImageComponent]
})
export class PlannedPlayEditDialogComponent {

    selectedGame?: BoardGameBasicResponse

    title?: string
    description?: string
    date?: Date
    startTime?: string
    location?: LocationResponse
    slots?: number
    visibility: Visibility = Visibility.PUBLIC

    imageUrl?: string
    imageFile?: Blob

    meetup?: MeetupResponse

    saving = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            plannedPlay?: PlannedPlayResponse,
            meetup?: MeetupResponse
        },
        private dialogRef: MatDialogRef<PlannedPlayEditDialogComponent>,
        private meetupService: MeetupService,
        private boardGameService: BoardGameService,
        private snackBar: MatSnackBar,
        public breakpointObserver: BreakpointObserver,
        private sharedDialogsService: SharedDialogsService,
        private authedUserService: AuthedUserService,
        private currentViewDataService: CurrentViewDataService,
    ) {
        this.authedUserService.assertAuthedUser()
        this.meetup = data.meetup || data.plannedPlay?.meetup

        this.title = data.plannedPlay?.title || data.plannedPlay?.game?.name
        this.description = data.plannedPlay?.description
        const startsAt = data.plannedPlay?.startsAt || this.meetup?.startsAt
        this.date = startsAt ? new Date(startsAt) : undefined
        this.startTime = startsAt ? new Date(startsAt).toTimeString().substring(0, 5) : undefined
        //this.date = data.plannedPlay?.time ? new Date(data.plannedPlay.time) : new Date()
        //this.startTime = this.date.toTimeString().substring(0, 5)
        this.location = data.plannedPlay?.location || this.meetup?.location
        this.visibility = data.plannedPlay?.visibility || this.meetup?.visibility || Visibility.PUBLIC
        this.imageUrl = data.plannedPlay?.imageUrl
        this.slots = data.plannedPlay?.slots

        this.selectGame(data.plannedPlay?.game || this.currentViewDataService.selectedGame)
    }

    selectGame(game?: BoardGameBasicResponse) {
        if (!this.title || this.title == this.selectedGame?.name) {
            this.title = game?.name
        }
        if (!this.imageUrl || this.imageUrl == this.selectedGame?.image?.url) {
            this.imageUrl = game?.image?.url
        }

        this.selectedGame = game

        if (game && !this.slots) {
            this.boardGameService.getGame(game!.slug).subscribe({
                next: response => {
                    this.slots = response.maxPlayerCount
                }
            })
        } else if (!game) {
            this.slots = undefined
        }
    }

    save() {
        if (
            !(this.selectedGame || (this.title && this.slots)) ||
            !(this.meetup || (this.date && this.startTime && this.location)) ||
            this.saving
        ) {
            return
        }

        // Check that the date is within the meetup's date range
        if (this.meetup && this.date &&
            (this.date < new Date(this.meetup.startsAt) ||
                this.date > new Date(this.meetup.endsAt || this.meetup.startsAt))) {
            let dateRange = this.meetup.startsAt
            if (this.meetup.endsAt) {
                dateRange += ' - ' + this.meetup.endsAt
            }
            this.snackBar.open($localize`The planned play date must be within the meetup's dates (${dateRange})`, 'OK', {duration: 5000})
            return
        }

        let startsAt: string | undefined
        if (this.date && this.startTime) {
            startsAt = getDate(this.date, this.startTime).toISOString()
        }
        const request: PlannedPlayRequest = {
            gameId: this.selectedGame?.id,
            meetupId: this.meetup?.id,
            title: this.selectedGame?.name == this.title ? undefined : this.title,
            description: this.description,
            startsAt: startsAt,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            locationId: this.meetup?.location?.id == this.location?.id ? undefined : this.location?.id,
            slots: this.slots,
            visibility: this.visibility,
        }

        this.saving = true
        if (!this.data.plannedPlay) {
            this.meetupService.createPlannedPlay(request).subscribe({
                next: response => {
                    this.saving = false
                    this.dialogRef.close(response.id)
                },
                error: error => {
                    this.saving = false
                    this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
                }
            })
        } else {
            this.meetupService.updatePlannedPlay(this.data.plannedPlay!.id, request).subscribe({
                next: response => {
                    this.saving = false
                    this.dialogRef.close()
                },
                error: error => {
                    this.saving = false
                    this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
                }
            })
        }
    }

    openLocationsDialog() {
        this.sharedDialogsService.openLocations(true, this.visibility).subscribe(location => {
            if (location) {
                this.location = location
            }
        })
    }

    protected readonly visibilities = visibilities;
}
