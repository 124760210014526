import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {GroupMembersResponse, GroupRequest, GroupResponse, GroupsResponse} from "../../model/group-dtos";

@Injectable()
export class GroupService {

    constructor(
        private http: HttpClient,
    ) {
    }

    searchGroups(pageSize: number, pageIndex: number, fromLatitude?: number, fromLongitude?: number): Observable<GroupsResponse> {
        let params = new HttpParams()
            .set('pagination', pageSize + ',' + pageIndex)

        if (fromLatitude && fromLongitude) {
            params = params.append('fromLatitude', fromLatitude.toString());
            params = params.append('fromLongitude', fromLongitude.toString());
        }
        return this.http.get<GroupsResponse>(`${environment.apiUrl}/groups/search`, {params: params});
    }

    getGroup(id: string): Observable<GroupResponse> {
        return this.http.get<GroupResponse>(`${environment.apiUrl}/groups/${id}`);
    }

    getGroupMembers(id: string, pageSize: number, pageIndex: number): Observable<GroupMembersResponse> {
        return this.http.get<GroupMembersResponse>(`${environment.apiUrl}/groups/${id}/members`, {
            params: new HttpParams()
                .set('pagination', pageSize + ',' + pageIndex)
        });
    }

    getGroupCollection(id: string, ownership: string): Observable<any> {
        throw new Error("Method not implemented.");
    }

    getGroupGamePlays(id: string, period: string, pageSize: number, pageIndex: number): Observable<any> {
        throw new Error("Method not implemented.");
    }

    getGroupGamePlayPeriodStats(id: string, period: string): Observable<any> {
        throw new Error("Method not implemented.");
    }

    getGroupStats(id: string): Observable<any> {
        throw new Error("Method not implemented.");
    }

    createGroup(request: GroupRequest, attachment?: Blob): Observable<GroupResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        if (attachment) {
            formData.append(`attachment-0`, attachment);
        }
        return this.http.post<GroupResponse>(`${environment.apiUrl}/groups`, formData);
    }

    updateGroup(id: string, request: GroupRequest, attachment?: Blob): Observable<any> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        if (attachment) {
            formData.append(`attachment-0`, attachment);
        }
        return this.http.put<any>(`${environment.apiUrl}/groups/${id}`, formData);
    }

    deleteGroup(id: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/groups/${id}`);
    }

    joinGroup(id: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/join`, {});
    }

    inviteToGroup(id: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/invite`, {});
    }

    acceptGroupInvitation(id: string, code: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/accept`, {code});
    }

    acceptGroupMembershipRequest(id: string, memberId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/${memberId}/accept`, {});
    }

    rejectGroupMembershipRequest(id: string, memberId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/groups/${id}/members/${memberId}/reject`, {});
    }

    removeMember(id: string, memberId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/groups/${id}/members/${memberId}`);
    }

    updateGroupMember(id: string, memberId: string, request: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/groups/${id}/members/${memberId}`, request);
    }
}
