import {LocationResponse, Page, UserBasicResponse} from "./responses";
import {Visibility} from "./visibility";
import {UserGameOwnership} from "./user-game-ownership";

export interface GroupsResponse {
    groups: GroupResponse[];
}

export interface GroupResponse {
    id: string
    name: string
    description: string
    imageUrl?: string
    visibility: Visibility
    joinPolicy: GroupJoinPolicy
    location?: LocationResponse
    createdAt: string
    memberCount?: number
    ownRole?: GroupRole
    membershipRequestCount?: number
}

export interface GroupMembersResponse {
    members: Page<GroupMemberResponse>
    pending: UserBasicResponse[]
}

export interface GroupMemberResponse {
    user: UserBasicResponse
    role: GroupRole
}

export interface GroupInviteResponse {
    code: string
}

export interface GroupGameAnnotations {
    avgRating?: number
    users: GroupUserGameAnnotations[]
}

export interface GroupUserGameAnnotations {
    userId: string
    ownership?: UserGameOwnership
    ownershipSince?: string
    rating?: number
}

export interface GroupRequest {
    name: string
    description: string
    imageUrl?: string
    visibility: Visibility
    joinPolicy: GroupJoinPolicy
    locationId?: string
}

export interface GroupMemberRequest {
    role: GroupRole
}

export interface GroupMembershipAcceptRequest {
    code: string
}

export enum GroupRole {
    ADMIN = "ADMIN",
    MEMBER = "MEMBER",
}

export enum GroupJoinPolicy {
    OPEN = "OPEN",
    REQUEST = "REQUEST",
    INVITE_ONLY = "INVITE_ONLY",
}
