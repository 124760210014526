import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
    GamePlayPeriodStatsResponse, GamePlayResponse, UserBasicResponse, UserPlayerResponse,
} from "../model/responses";
import {ActivatedRoute} from "@angular/router";
import {NavigationService} from "../service/ui/navigation.service";
import {GamePlayService} from "../service/api/game-play.service";
import {GameGridComponent, ListGame} from '../game/game-grid.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {CommonModule, Location} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ShareService} from "../service/ui/share.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {PeriodSelectComponent} from "../shared/period-select.component";
import {datedPeriod, Period, periodFromRequest, periodToRequest} from "../model/period";
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {GamePlayListComponent} from "../play/game-play-list.component";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";
import {AuthedUserService} from "../service/authed-user.service";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";

@Component({
    selector: 'cb-user-play-period-stats',
    template: `
        <div class="search-game-form row space-between align-center" *ngIf="displayButtons">
            <div class="row end" *ngIf="displayButtons">
                <button mat-icon-button class="small-icon-button mode-button" [class.selected]="gridView"
                   (click)="setGridView(true)">
                    <mat-icon>grid_view</mat-icon>
                </button>
                <button mat-icon-button class="small-icon-button mode-button" [class.selected]="!gridView"
                   (click)="setGridView(false)">
                    <mat-icon>view_list</mat-icon>
                </button>
            </div>
            <mat-slide-toggle *ngIf="currentUserId && user.id != currentUserId"
                              [(ngModel)]="withCurrentUser"
                              (change)="reload()"
                              i18n>With me</mat-slide-toggle>
            <button mat-icon-button class="small-icon-button" (click)="share()" *ngIf="displayButtons && userPlayer">
                <mat-icon>share</mat-icon>
            </button>
            <button mat-icon-button class="small-icon-button" [matMenuTriggerFor]="shareMenu" *ngIf="displayButtons && !userPlayer">
                <mat-icon>share</mat-icon>
            </button>
            <mat-menu #shareMenu="matMenu">
                <div class="column">
                    <button class="menu-item" mat-menu-item (click)="share(false)">
                        <mat-icon>link</mat-icon>
                        <span i18n>Link</span>
                    </button>
                    <button class="menu-item" mat-menu-item (click)="share(true)">
                        <mat-icon>image</mat-icon>
                        <span i18n>Image</span>
                    </button>
                </div>
            </mat-menu>
        </div>
        <div class="search-game-form row center align-center" style="margin-bottom: 8px">
            <cb-period-select [selectedPeriod]="period" big
                              (selectedPeriodChange)="selectPeriod($event)"></cb-period-select>
        </div>
        <ng-container *ngIf="gridView">
            <div class="counts row space-between align-center" *ngIf="periodResponse?.playStats?.totalPlayCount">
                <span i18n>{{ periodResponse?.playStats?.totalPlayCount }} plays</span>
                <span i18n>Play time: {{ periodResponse?.playStats?.totalAdjustedPlayTime }}</span>
            </div>
            <div *ngIf="periodResponse && periodResponse.games.length > 0" style="margin: 4px 12px">
                <cb-game-grid [games]="displayedGames" [gamesPerRow]="gamesPerRow"
                              (onGameSelected)="navigateToGame($event)"></cb-game-grid>
            </div>
            <div class="column center align-center"
                 [class.big-no-content-message-container]="bigNoContentMessage"
                 [class.no-content-message-container]="!bigNoContentMessage"
                 *ngIf="periodResponse && !periodResponse.games.length">
                <span class="no-content-message" i18n>No plays</span>
            </div>
            <div class="column center align-center" *ngIf="!periodResponse && gettingStats" style="height: 400px">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!gridView">
            <div style="margin: 0 12px">
                <cb-game-play-list *ngIf="userPlays" [plays]="userPlays"></cb-game-play-list>
            </div>
        </ng-container>
    `,
    styles: [`
        .search-game-form {
            margin: 8px 16px -8px 16px
        }

        .counts {
            margin: 8px 24px 0 24px;
        }

        .mode-button {
            color: var(--mat-app-text-color);
        }

        .selected {
            color: var(--primary-color);
        }

        .no-content-message-container {
            margin: 24px 16px;

            .no-content-message {
                font-size: 1.6rem;
                font-weight: 700;
                text-align: center;
                color: var(--secondary-text-color);
            }
        }

        .big-no-content-message-container {
            margin: 96px 16px;

            .no-content-message {
                font-size: 2rem;
                font-weight: 700;
                text-align: center;
                color: var(--secondary-text-color);
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatIconModule,
        GameGridComponent, MatProgressSpinnerModule, PeriodSelectComponent, MatButtonToggle, MatButtonToggleGroup, GamePlayListComponent, MatSlideToggle, FormsModule, MatMenu, MatMenuItem, MatMenuTrigger
    ]
})
export class UserPlayPeriodStatsComponent implements OnInit, OnChanges {

    @Input()
    user: UserBasicResponse

    @Input()
    userPlayer: UserPlayerResponse | undefined

    @Input()
    gamesPerRow: number | undefined

    @Input()
    maxGames: number | undefined

    @Input()
    displayButtons: boolean = true

    @Input()
    bigNoContentMessage: boolean = true

    @Input()
    route?: string

    periodResponse?: GamePlayPeriodStatsResponse
    displayedGames: ListGame[] = []

    period = datedPeriod(Period.THIRTY_DAYS);

    userPlays?: GamePlayResponse[]

    gridView: boolean = true

    withCurrentUser: boolean = false

    currentUserId = this.authedUserService.getUserId()

    constructor(
        private gamePlayService: GamePlayService,
        private activatedRoute: ActivatedRoute,
        public navigationService: NavigationService,
        private shareService: ShareService,
        private location: Location,
        private authedUserService: AuthedUserService,
    ) {
    }

    init = false
    ngOnInit(): void {
        this.period = periodFromRequest(
            this.activatedRoute.snapshot.queryParamMap.get('period') || 'THIRTY_DAYS'
        )
        const viewParam = this.activatedRoute.snapshot.queryParamMap.get('view')
        this.gridView = viewParam ? viewParam != 'LIST' : localStorage.getItem('user-play-period-stats-grid-view') != 'false'
        this.loadList()
        this.getPeriodStats(true)
        this.init = true
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['user'] || !this.init) {
            return
        }
        this.getPeriodStats()
    }

    gettingStats: boolean = false

    getPeriodStats(updateScroll: boolean = false) {
        if (this.gettingStats) {
            return
        }
        this.gettingStats = true
        this.periodResponse = undefined
        //console.log('getPeriodStats', this.user.username, this.period.value, dateToString(new Date()))
        this.gamePlayService.getUserGamePlayPeriodStats(this.user.username, this.period, this.userPlayer?.id, this.withCurrentUser ? this.currentUserId : undefined).subscribe({
            next: response => {
                this.periodResponse = response
                const games = response.games.slice(0, this.maxGames || response.games.length)
                this.displayedGames = games.map(gameStat => {
                    return {
                        ...gameStat,
                        topRightNumber: gameStat.stats.totalPlayCount,
                        bottomRightLabel: gameStat.stats.totalAdjustedPlayTime,
                    }
                })
                if (updateScroll) {
                    this.navigationService.updateScrollPosition()
                }
                this.gettingStats = false
            },
            error: error => {
                this.gettingStats = false
            }
        })
    }

    reload() {
        if (this.gridView) {
            this.getPeriodStats()
        } else {
            this.userPlays = undefined
            this.loadList()
        }
    }

    loadList() {
        if (!this.gridView && !this.userPlays) {
            this.gamePlayService.getUserGamePlaysPeriod(this.user.username, this.period, this.userPlayer?.id, this.withCurrentUser ? this.currentUserId : undefined).subscribe(plays => {
                this.userPlays = plays.elements
                this.navigationService.updateScrollPosition()
            })
        }
    }

    selectPeriod(period: any) {
        if (period != this.period) {
            this.period = period
            if (this.route) {
                this.location.replaceState(this.route, `?period=${periodToRequest(period)}${this.gridView ? '' : '&view=LIST'}`)
            }
            this.getPeriodStats()

            this.userPlays = undefined
            this.loadList()
        }
    }

    setGridView(gridView: boolean) {
        if (gridView != this.gridView) {
            this.gridView = gridView
            if (!this.gridView) {
                this.loadList()
            }
            if (this.route) {
                this.location.replaceState(this.route, `?period=${periodToRequest(this.period)}${this.gridView ? '' : '&view=LIST'}`)
            }
            localStorage.setItem('user-play-period-stats-grid-view', this.gridView ? 'true' : 'false')
        }
    }

    share(image?: boolean) {
        const baseLink = this.userPlayer?.id ? `player/${this.userPlayer?.id}` : `${this.user.username}/plays`
        this.shareService.shareLink(
            $localize`${this.user.name}'s Plays @ Ludoya`,
            $localize`Take a look at these board game plays`,
            `${baseLink}?view=${this.gridView ? 'GRID' : 'LIST'}&period=${periodToRequest(this.period)}`,
            image
        )
    }

    navigateToGame(gameSlug: string) {
        this.navigationService.navigate(['/g', gameSlug], false)
    }
}
