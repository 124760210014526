import {Component, EventEmitter, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
      <div class="column align-center">
        <p>{{data.content}}</p>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>{{data.inputLabel}}</mat-label>
          <input matInput [type]="data.inputType" [(ngModel)]="inputValue">
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions class="row space-around">
      <button mat-button mat-dialog-close i18n>Cancel</button>
      <button mat-flat-button color="primary" (click)="dialogRef.close(inputValue)" i18n>Confirm</button>
    </div>
  `,
    styles: [`
  `],
    standalone: true,
    imports: [MatDialogModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatButtonModule]
})
export class InputDialogComponent {

  inputValue?: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string, content: string, inputLabel: string, inputType: string},
    public dialogRef: MatDialogRef<InputDialogComponent>
  ) {
  }
}
