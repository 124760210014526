import {Component, Inject} from "@angular/core";
import {LocationResponse, MeetupResponse} from "../model/responses";
import {MeetupRequest} from "../model/requests";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BreakpointObserver} from "@angular/cdk/layout";
import {MeetupService} from "../service/api/meetup.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {NgIf, NgFor} from "@angular/common";
import {HeaderComponent} from "../shared/header.component";
import {SharedDialogsService} from "../service/shared-dialogs.service";
import {visibilities, Visibility} from "../model/visibility";
import {
    MAT_DIALOG_DATA,
    MatDialogActions, MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {AuthedUserService} from "../service/authed-user.service";
import {AvatarComponent} from "../user/avatar.component";
import {ImageComponent} from "../shared/image.component";
import {getDate} from "../model/utils";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Organize Meetup</h1>
        <mat-dialog-content>
            <form class="add-content-form column" *ngIf="!saving">
                <mat-form-field style="width: 100%" subscriptSizing="dynamic">
                    <mat-label i18n>Meetup Title</mat-label>
                    <input matInput [(ngModel)]="title" name="title" required>
                </mat-form-field>
                <div class="row center" style="margin: 8px 0">
                    <cb-image width="300" height="150" [imageUrl]="imageUrl" editable
                                       (imageChange)="imageFile = $event">
                    </cb-image>
                </div>
                <mat-form-field style="width: 100%">
                    <mat-label i18n>Meetup Description</mat-label>
                    <textarea matInput cdkTextareaAutosize [(ngModel)]="description" name="description"></textarea>
                </mat-form-field>
                <div class="row center align-center" style="margin-bottom: 16px">
                    <button mat-stroked-button *ngIf="location" (click)="openLocationsDialog()"
                            style="width: 100%; height: 48px; background-color: var(--mat-app-background-color-variant)">
                        <mat-icon>location_on</mat-icon>
                        <span>{{ location.name }}</span>
                    </button>
                    <button mat-icon-button class="small-icon-button" *ngIf="location" (click)="location = undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-stroked-button *ngIf="!location" (click)="openLocationsDialog()"
                            style="width: 100%; height: 48px; background-color: var(--mat-app-background-color-variant)">
                        <mat-icon>not_listed_location</mat-icon>
                        <span style="color: var(--secondary-text-color); font-size: 16px" i18n>Set Location</span>
                        <span>*</span>
                    </button>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label i18n>Meetup Date</mat-label>
                    <input matInput [matDatepicker]="picker" [value]="date"
                           readonly
                           (click)="picker.open()"
                           (focus)="picker.open()"
                           (dateChange)="date = $event.value" #dateInput>
                    <mat-datepicker-toggle matSuffix *ngIf="!date" [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-icon-button matSuffix *ngIf="date"
                            (click)="$event.stopPropagation(); date = undefined; dateInput.blur()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <div class="row space-between">
                    <mat-form-field appearance="fill" class="fill" style="margin-right: 8px">
                        <mat-label i18n>Start Time</mat-label>
                        <input matInput type="time" name="startTime" [(ngModel)]="startTime">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="fill" style="margin-left: 8px">
                        <mat-label i18n>End Time</mat-label>
                        <input matInput type="time" name="endTime" [(ngModel)]="endTime">
                    </mat-form-field>
                </div>
                <div class="row space-between align-center">
                    <mat-form-field appearance="fill" class="fill">
                        <mat-label i18n>Visibility</mat-label>
                        <mat-select [(value)]="visibility">
                            <mat-option *ngFor="let settingType of visibilities"
                                        [value]="settingType.type">{{settingType.meetupLabel}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div class="saving column center align-center" *ngIf="saving">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-flat-button color="primary" (click)="save()"
                    [disabled]="!title || !date || !startTime || !endTime || !location || saving"
                    i18n>Save</button>
        </div>
    `,
    styles: [`
        .add-content-form {
            margin: 8px;
        }

        .section-separation {
            margin-bottom: 16px;
        }

        .saving {
            margin-top: 32px;
        }

        .write-post-attachment-buttons {
            margin-top: 10px;
            margin-right: 10px;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [HeaderComponent, NgIf, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatIconModule, MatDatepickerModule, MatProgressSpinnerModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, AvatarComponent, ImageComponent]
})
export class MeetupEditDialogComponent {

    title?: string
    description?: string
    location?: LocationResponse
    date?: Date
    startTime?: string = '16:00'
    endTime?: string = '20:00'
    visibility: Visibility = Visibility.PUBLIC

    imageUrl?: string
    imageFile?: Blob

    saving = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            meetup?: MeetupResponse
        },
        private dialogRef: MatDialogRef<MeetupEditDialogComponent>,
        private meetupService: MeetupService,
        private snackBar: MatSnackBar,
        public breakpointObserver: BreakpointObserver,
        private sharedDialogsService: SharedDialogsService,
        private authedUserService: AuthedUserService,
    ) {
        this.authedUserService.assertAuthedUser()

        this.title = data.meetup?.title
        this.description = data.meetup?.description
        this.location = data.meetup?.location
        this.date = data.meetup?.startsAt ? new Date(data.meetup.startsAt) : undefined
        this.startTime = data.meetup?.startsAt ? new Date(data.meetup?.startsAt).toTimeString().substring(0, 5) : undefined
        this.endTime = data.meetup?.endsAt ? new Date(data.meetup?.endsAt).toTimeString().substring(0, 5) : undefined
        this.visibility = data.meetup?.visibility || Visibility.PUBLIC
        this.imageUrl = data.meetup?.imageUrl
    }

    save() {
        if (!this.title || !this.date || !this.startTime || !this.endTime || !this.location || this.saving) {
            return
        }

        const request: MeetupRequest = {
            title: this.title,
            description: this.description,
            locationId: this.location?.id,
            startsAt: getDate(this.date, this.startTime).toISOString(),
            endsAt: this.endTime ? getDate(this.date, this.endTime).toISOString() : undefined,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            visibility: this.visibility
        }

        this.saving = true
        if (!this.data.meetup) {
            this.meetupService.createMeetup(request, this.imageFile).subscribe({
                next: response => {
                    this.saving = false
                    this.dialogRef.close(response.id)
                },
                error: error => {
                    this.saving = false
                    this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
                }
            })
        } else {
            this.meetupService.updateMeetup(this.data.meetup!.id, request, this.imageFile).subscribe({
                next: response => {
                    this.saving = false
                    this.dialogRef.close()
                },
                error: error => {
                    this.saving = false
                    this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
                }
            })
        }
    }

    openLocationsDialog() {
        this.sharedDialogsService.openLocations(true, this.visibility).subscribe(location => {
            if (location) {
                this.location = location
            }
        })
    }

    protected readonly visibilities = visibilities;
}
