import {Component, EventEmitter, Input, Output} from "@angular/core";
import {NavigationService} from "../service/ui/navigation.service";
import {CommonModule} from "@angular/common";
import {datedPeriod, DatedPeriod, datedPeriodToString, Period, periodData} from "../model/period";
import {MatButtonModule, MatIconButton} from "@angular/material/button";
import {MatIcon, MatIconModule} from "@angular/material/icon";

@Component({
    selector: 'cb-banner',
    template: `
        <div class="row space-between align-start banner" *ngIf="!dismissed">
            <div class="row space-between align-center">
                <span class="message">{{message}}</span>
                <button mat-flat-button color="primary" *ngIf="actionLabel" (click)="actionSelected.emit()">
                    {{actionLabel}}
                </button>
            </div>
            <button mat-icon-button class="very-small-icon-button" (click)="dismiss()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    `,
    styles: [`
        .banner {
            background-color: var(--mat-app-background-color-variant);
            border: 1px solid #3f5f7a;
            border-radius: 4px;
            margin: 8px;
        }

        .message {
            margin: 8px;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule, MatIconModule,
    ]
})
export class BannerComponent {

    @Input()
    message: string
    @Input()
    actionLabel: string | undefined
    @Output()
    actionSelected = new EventEmitter<any>()

    dismissed = false

    constructor(
    ) {
    }

    ngOnInit() {
    }

    dismiss() {
        this.dismissed = true
    }
}
