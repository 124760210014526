import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {booleanAttribute, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {GamePlayerResponse, GamePlayState, UserBasicResponse, UserFavState} from "../model/responses";
import {AuthedUserService} from "../service/authed-user.service";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserFavService} from "src/app/service/api/user-fav.service";
import {UserSearchIntent} from "../model/user";
import {MatOptionModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {SearchService} from "../service/api/search.service";
import {GamePlayService} from "../service/api/game-play.service";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {AvatarComponent} from "../user/avatar.component";

@Component({
    selector: 'cb-players-selection-form',
    template: `
        <form class="log-play-form column fillWidth">
            <mat-form-field class="example-chip-list">
                <mat-label>{{ label }}</mat-label>
                <mat-chip-grid #chipGrid i18n-aria-label aria-label="Player selection">
                    <mat-chip-row *ngFor="let player of players" (removed)="removePlayer(player)">
                        <cb-avatar matChipAvatar style="margin-right: -4px" [size]="18"
                                [name]="player.name" [avatarUrl]="player.avatarUrl">
                        </cb-avatar>
                        <span class="player-name">{{ player.name }}</span>
                        <span class="player-username" *ngIf="player.username">{{ '@' + player.username }}</span>
                        <span class="player-username" *ngIf="!player.username" i18n>(Non-user player)</span>
                        <button matChipRemove [attr.aria-label]="'remove ' + player">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <input #playerInput [formControl]="playerControl"
                       [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       (input)="onInputType($event)"
                       (matChipInputTokenEnd)="addPlayer($event)"
                />
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngIf="allowNonUserPlayers && playerControl.value && playerControl.value!.length > 0 &&
                                playerControl.value!.charAt(0) != '@' && playerControl.value!.charAt(0) != ' '"
                                [value]="playerControl.value" (onSelectionChange)="addPlayer($event.source)">
                        <div class="row left align-center" style="padding: 5px">
                            <cb-avatar matChipAvatar [size]="34" style="min-width: 34px"
                                       [name]="playerControl.value!">
                            </cb-avatar>
                            <span class="player-name" style="margin-left: 10px">{{ playerControl.value }}</span>
                            <span class="player-username" i18n>(Non-user player)</span>
                        </div>
                    </mat-option>
                    <mat-option *ngFor="let option of filteredUsers" [value]="'@'+option.username"
                                (onSelectionChange)="addUser(option, $event)">
                        <div class="row left align-center" style="min-width: 34px">
                            <cb-avatar matChipAvatar [size]="34" style="min-width: 34px"
                                       [name]="option.name" [avatarUrl]="option.avatarUrl">
                            </cb-avatar>
                            <span class="player-name" style="margin-left: 10px">{{ option.name }}</span>
                            <span *ngIf="option.username" class="player-username">&#64;{{ option.username }}</span>
                            <span *ngIf="!option.username" class="player-username" i18n>(Non-user player)</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    `,
    styles: [`
        .section-separation {
            margin-bottom: 16px;
        }

        .log-play-form {

            .mat-form-field {
                width: 100%;
            }

            .log-full-width {
                width: 100%;
            }

            .add-player-field {
                margin-top: 10px;
            }
        }

        .player-name {
            font-size: 1rem;
            font-weight: 700;
            white-space: nowrap;
        }

        .player-username {
            margin-left: 6px;
            font-size: 1rem;
            font-weight: 400;
            color: var(--secondary-text-color);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        MatFormFieldModule, MatInputModule, MatChipsModule, MatIconModule, MatAutocompleteModule, MatOptionModule, MatButtonModule, AvatarComponent
    ]
})
export class PlayersSelectionFormComponent {

    separatorKeysCodes: number[] = [ENTER, COMMA];

    playerControl = new FormControl('');

    lastPlayedWith: GamePlayerResponse[] = []
    friends: UserBasicResponse[] = []

    filteredUsers: GamePlayerResponse[] = []

    @Input()
    label: string = $localize`Players`
    @Input()
    players: GamePlayerResponse[] = []
    @Input({transform: booleanAttribute})
    allowNonUserPlayers: boolean = true
    @Input({transform: booleanAttribute})
    onlyFriends: boolean = false
    @Output()
    playersChange = new EventEmitter<GamePlayerResponse[]>()

    @ViewChild('playerInput') playerInput: ElementRef<HTMLInputElement>;

    constructor(
        private searchService: SearchService,
        private userFavService: UserFavService,
        private authedUserService: AuthedUserService,
        private gamePlayService: GamePlayService
    ) {
    }

    ngOnInit() {
        this.authedUserService.assertAuthedUser()
        const user = this.authedUserService.getUserData()!
        if (this.players.length == 0) {
            const userPlayer = {name: user.name, userId: user.id, username: user.username, avatarUrl: user.avatarUrl}
            this.players.push(userPlayer)
            this.updateData()
        }
        this.userFavService.getUserFavs(user.id).subscribe(favs => {
            const friends: UserBasicResponse[] = favs.list
                .filter(f => f.state == UserFavState.RECIPROCAL)
                .map(f => { return {...f} })
            this.friends = friends.concat(this.authedUserService.getUserData()!)
            this.performFilter('')
        })
        if (!this.onlyFriends) {
            this.gamePlayService.getUserGamePlays(user.username, [GamePlayState.FINISHED]).subscribe(plays => {
                const players = plays.elements
                    .flatMap(p => p.players)
                // Remove duplicates
                const uniquePlayers: GamePlayerResponse[] = []
                players.forEach(p => {
                    if (!uniquePlayers.some(u =>
                        (u.userId && u.userId == p.userId) || (!u.userId && !p.userId && u.name == p.name)
                    )) {
                        uniquePlayers.push(p)
                    }
                })
                this.lastPlayedWith = uniquePlayers.map(p => {
                    return {
                        name: p.name,
                        userId: p.userId,
                        username: p.username,
                        avatarUrl: p.avatarUrl
                    }
                })
                this.performFilter('')
            })
        }
    }

    onInputType(event: any) {
        this.performFilter(event.target.value)
    }

    performFilter(filter: string) {
        const filteredPrevious = this.lastPlayedWith.filter(f =>
            !this.players.some(p =>
                (p.userId && p.userId == f.userId) || (!p.userId && !f.userId && p.name == f.name)
            )
        )
        const filteredFriends = this.friends.filter(f =>
            !this.players.some(p =>
                p.userId == f.id || filteredPrevious.some(p => p.userId == f.id)
            )
        ).map(f => {
            return {name: f.name, userId: f.id, username: f.username, avatarUrl: f.avatarUrl}
        })
        let filteredBase = filteredPrevious.concat(filteredFriends)
        if (filter.length < 1) {
            this.filteredUsers = filteredBase.slice(0, 20)
            return
        }
        const onlyUsers = filter.charAt(0) == '@'
        if (onlyUsers) {
            filter = filter.substring(1)
        }
        filteredBase = filteredBase.filter(f =>
            (!onlyUsers && f.name.toLowerCase().includes(filter.toLowerCase())) ||
            (f.username && f.username.toLowerCase().includes(filter.toLowerCase()))
        ).slice(0, 20)
        this.filteredUsers = filteredBase
        if (this.onlyFriends) {
            return
        }
        this.searchService.searchUsers(filter, UserSearchIntent.PLAY).subscribe(users => {
            const filteredUsers = users.list
                .filter(u => !filteredBase.some(f => f.userId == u.id))
                .filter(f => !this.players.some(p => p.userId == f.id))
                .map(u => {
                    return {name: u.name, userId: u.id, username: u.username, avatarUrl: u.avatarUrl}
                })
            this.filteredUsers = filteredBase.concat(filteredUsers)
        })
    }

    addUser(user: GamePlayerResponse, event: any) {
        // Trigger only at select
        if (event.source.selected) {
            this.players.push(user)
            setTimeout(() => {
                this.playerControl.reset()
                this.playerControl.setValue('')
                this.playerInput.nativeElement.value = ''
            }, 100)
            if (user.userId) {
                this.filteredUsers = this.filteredUsers.filter(u => u.userId != user.userId)
            } else {
                this.filteredUsers = this.filteredUsers.filter(u => u.userId || u.name != user.name)
            }
            this.updateData()
        }
    }

    addPlayer(event: any) {
        if (event.value && this.players.every(p => p.name != event.value)) {
            this.players.push({name: event.value})
        }
        setTimeout(() => {
            this.playerControl.reset()
            this.playerControl.setValue('')
            this.playerInput.nativeElement.value = ''
        }, 100)
        this.updateData()
    }

    removePlayer(player: GamePlayerResponse) {
        this.players = this.players.filter(p => p != player)
        this.updateData()
    }

    updateData() {
        this.playersChange.emit(this.players)
        this.performFilter('')
    }
}
