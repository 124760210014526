import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {preview, removePreview} from "../shared/image-upload.component";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'cb-attach-image-preview',
    template: `
        <div class="image-preview-container">
            <img [id]="previewId" class="image-preview" src="#" [alt]="previewId">
            <div *ngIf="editable" class="image-remove-button" (click)="onDeleted.emit()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
    `,
    styles: [`
        .image-preview-container {
            position: relative;
            width: 90%;
            height: 100%;
            overflow: hidden;
            //max-height: 300px;
            padding: 5px;

            .image-preview {
                width: 100%;
                height: 100%;
                max-height: 300px;
                border-radius: 5px;
                object-fit: cover;
            }

            .image-remove-button {
                position: absolute;
                top: 7px;
                left: 7px;
                width: 30px;
                height: 30px;
                cursor: pointer;
                border-radius: 50%;
                overflow: hidden;

                background-color: rgba(0, 0, 0, 0.7);

                .mat-icon {
                    color: white;
                    font-size: 20px;
                    padding: 5px;

                    width: 20px;
                    height: 20px;
                }
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule
    ]
})
export class AttachImagePreviewComponent implements OnInit, OnChanges {
    @Input()
    editable: boolean = true
    @Input()
    previewId: string = 'image-preview'
    @Input()
    file?: Blob
    @Output()
    onDeleted = new EventEmitter<any>()

    ngOnInit() {
        if (this.file) {
            preview(this.file, this.previewId)
        }
    }

    ngOnChanges(): void {
        if (this.file) {
            preview(this.file, this.previewId)
        } else {
            removePreview(this.previewId)
        }
    }
}
