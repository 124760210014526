import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserPlayerResponse} from "../../model/responses";
import {GamePlayService} from "../../service/api/game-play.service";
import {MatButtonModule} from "@angular/material/button";
import {AvatarComponent} from "../avatar.component";
import {MatIcon} from "@angular/material/icon";
import {AuthedUserService} from "../../service/authed-user.service";
import {NavigationService} from "../../service/ui/navigation.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogService} from "../../service/ui/dialog.service";
import {UserPlayPeriodStatsComponent} from "../user-play-period-stats.component";
import {MatDivider} from "@angular/material/divider";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserPlayerTagSelfDialogComponent} from "./user-player-tag-self-dialog.component";
import {EditUserPlayerDialogComponent} from "./edit-user-player-dialog.component";
import {BannerComponent} from "../../shared/banner.component";

@Component({
    selector: 'cb-user-player',
    template: `
        <cb-banner *ngIf="userPlayer && !userPlayer.linkedUser && inviteCode"
                   i18n-message message="{{userPlayer.creator.name}} invited you to tag yourself as this player"
        ></cb-banner>
        <div class="column user-player" *ngIf="userPlayer">
            <div class="row space-between align-center">
                <cb-avatar [name]="userPlayer.name"
                           [avatarUrl]="userPlayer.avatarUrl"
                           [isBot]="userPlayer.isBot"
                           [size]="72"></cb-avatar>
                <div class="column space-between align-end">
                    <button mat-fab extended color="accent" *ngIf="!userPlayer.linkedUser && inviteCode"
                            (click)="tagSelf()" style="margin-bottom: 8px">
                        <mat-icon>verified</mat-icon>
                        <span i18n>This is me!</span>
                    </button>
                    <button mat-flat-button color="primary" *ngIf="userPlayer.creator.id == currentUser?.id"
                            (click)="edit()">
                        <!--<mat-icon>edit</mat-icon>-->
                        <span i18n>Edit</span>
                    </button>
                </div>
            </div>
            <div class="name-container row space-between align-end">
                <span class="name">{{ userPlayer.name }}</span>
            </div>
            <div class="row space-between wrap">
                <div class="row align-center creator-container">
                    <span style="margin-right: 4px" i18n>Managed by:</span>
                    <div class="row align-center"
                         (click)="navigationService.navigate([userPlayer.creator.username])"
                         style="cursor: pointer">
                        <cb-avatar [name]="userPlayer.creator.name"
                                   [avatarUrl]="userPlayer.creator.avatarUrl"
                                   [size]="24">
                        </cb-avatar>
                        <span class="name">{{ userPlayer.creator.name }}</span>
                        <span class="username">&#64;{{ userPlayer.creator.username }}</span>
                    </div>
                </div>
                <div class="row align-center creator-container" *ngIf="userPlayer.linkedUser">
                    <span style="margin-right: 4px" i18n>Tagged as:</span>
                    <div class="row align-center"
                         (click)="navigationService.navigate([userPlayer.linkedUser.username])"
                         style="cursor: pointer">
                        <cb-avatar [name]="userPlayer.linkedUser.name"
                                   [avatarUrl]="userPlayer.linkedUser.avatarUrl"
                                   [size]="24">
                        </cb-avatar>
                        <span class="name">{{ userPlayer.linkedUser.name }}</span>
                        <span class="username">&#64;{{ userPlayer.linkedUser.username }}</span>
                    </div>
                </div>
            </div>
            <mat-divider style="margin-top: 8px"></mat-divider>
            <span class="subtitle" i18n>Plays</span>
        </div>
        <cb-user-play-period-stats *ngIf="userPlayer" [user]="userPlayer.creator"
                                   [userPlayer]="userPlayer"></cb-user-play-period-stats>

    `,
    styles: [`
        .user-player {
            margin: 0 16px;
            color: white;
        }
        .name-container {
            margin-top: 10px;
            margin-bottom: 10px;

            .name {
                font-size: 1.4rem;
                font-weight: 700;
            }
        }

        .creator-container {
            margin-bottom: 4px;
            font-size: 1rem;
            font-weight: 400;
            color: var(--secondary-text-color);

            .name {
                margin-left: 4px;
                font-weight: 700;
                color: white;
            }
            .username {
                margin-left: 4px;
            }
        }

        .mdc-button {
            border-radius: 30px !important;
        }

        .subtitle {
            align-self: center;
            font-size: 1.1rem;
            font-weight: 700;
            letter-spacing: 1.25px;
            margin-top: 8px;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        AvatarComponent,
        MatIcon,
        UserPlayPeriodStatsComponent,
        MatDivider,
        BannerComponent,
    ]
})
export class UserPlayerComponent implements OnInit {

    @Input()
    userPlayerId: string
    @Input()
    inviteCode: string | undefined

    currentUser = this.authedUserService.getUserData()

    userPlayer?: UserPlayerResponse

    constructor(
        public navigationService: NavigationService,
        private gamePlayService: GamePlayService,
        private authedUserService: AuthedUserService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private snackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
        this.gamePlayService.getUserPlayer(this.userPlayerId).subscribe({
            next: user => {
                this.userPlayer = user
            }
        });
    }

    edit() {
        const dialogConfig: MatDialogConfig = {
            data: {id: this.userPlayerId},
            minWidth: '350px',
            maxWidth: '600px',
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(EditUserPlayerDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.afterClosed().subscribe(player => {
            if (player) {
                this.userPlayer = player
            }
        })
    }

    tagSelf() {
        if (!this.inviteCode || !this.userPlayer) {
            return
        }
        if (!this.currentUser) {
            this.snackBar.open('You must be logged in to tag yourself as this player', 'OK')
        }

        const dialogConfig: MatDialogConfig = {
            data: {userPlayer: this.userPlayer, inviteCode: this.inviteCode},
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(UserPlayerTagSelfDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.afterClosed().subscribe(() => {
            this.userPlayer!.linkedUser = this.currentUser
        })
    }
}
