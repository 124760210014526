import {Component, Inject} from "@angular/core";
import {BoardGameResponse, GamePlayerResponse} from "../model/responses";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {GameHelperListComponent} from "./game-helper-list.component";
import {CommonModule} from "@angular/common";
import {PlayersSelectionFormComponent} from "../play/players-selection-form.component";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {UserGameService} from "../service/api/user-game.service";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDividerModule} from "@angular/material/divider";
import {MatMenuModule} from "@angular/material/menu";
import {ConfirmDialogComponent} from "../shared/confirm-dialog.component";

@Component({
    selector: 'cb-choose-random-game-dialog',
    template: `
        <h1 mat-dialog-title i18n>Choose Random Game</h1>
        <mat-dialog-content>
            <div class="column center align-center flex" *ngIf="!loading && !done && !selectedGame">
                <span style="margin-bottom: 12px" i18n>Choose the number of players and the duration and complexity of the game you want to play.</span>
                <span style="margin-bottom: 12px" i18n>Our algorithm will then present you with a set of games randomly picked from your collections.</span>
                <span style="margin-bottom: 12px" i18n>Note: the selected duration and complexity are not strict filters, but rather a way to give the algorithm a hint about the type of game you want to play.</span>
                <mat-form-field class="fillWidth" appearance="fill">
                    <mat-label i18n>Player Count</mat-label>
                    <mat-select [value]="playerCount" (selectionChange)="selectPlayerCount($event)">
                        <mat-option [value]="0" i18n>Any</mat-option>
                        <mat-option [value]="1" i18n>Solo</mat-option>
                        <mat-option *ngFor="let playerCount of playerCounts" [value]="playerCount">
                            {{ playerCount }}
                        </mat-option>
                        <mat-option [value]="8">8+</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="row space-between align-center fillWidth">
                    <mat-form-field style="margin-right: 4px" class="fillWidth" appearance="fill">
                        <mat-label i18n>Duration</mat-label>
                        <mat-select [value]="length" (selectionChange)="selectLength($event)">
                            <mat-option *ngFor="let length of lengths" [value]="length.value">
                                {{ length.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="margin-left: 4px" class="fillWidth" appearance="fill">
                        <mat-label i18n>Complexity</mat-label>
                        <mat-select [value]="weight" (selectionChange)="selectWeight($event)">
                            <mat-option *ngFor="let weight of weights" [value]="weight.value">
                                {{ weight.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <cb-players-selection-form class="fillWidth"
                                           i18n-label label="Collections to pick from"
                                           [(players)]="collectionsToCheck"
                                           [allowNonUserPlayers]="false" onlyFriends
                ></cb-players-selection-form>
                <button mat-flat-button color="primary" (click)="start()" i18n>Suggest a game</button>
            </div>
            <div class="column center align-center flex" *ngIf="selectedGame" style="height: 100%">
                <div class="column">
                    <div class="row space-between align-center game-name" style="width: 100%">
                        <span>{{ selectedGame.name }} <span *ngIf="selectedGame.yearPublished">({{ selectedGame.yearPublished }})</span></span>
                    </div>
                </div>
                <div class="image-container">
                    <img [src]="selectedGame.image.url" alt="game image" class="game-image">
                </div>
                <div class="game-stats row space-between align-center">
                    <div class="stat-container column center align-center">
                        <span class="stat-value row align-center" *ngIf="selectedGame.minPlayerCount != selectedGame.maxPlayerCount">
                            <span>{{ selectedGame.minPlayerCount }}-{{ selectedGame.maxPlayerCount }}</span>
                            <span class="best" *ngIf="selectedGame.bestPlayerCount" i18n>(best: <span class="number" [class.mismatch]="selectedGame.bestPlayerCount != playerCount">{{ selectedGame.bestPlayerCount }}</span>)</span>
                        </span>
                        <span class="stat-value" *ngIf="selectedGame.minPlayerCount == selectedGame.maxPlayerCount">
                          {{ selectedGame.minPlayerCount }}
                        </span>
                        <span class="stat-label" i18n>Players</span>
                    </div>
                    <div class="stat-container column center align-center">
                        <span class="stat-value" *ngIf="selectedGame.minPlayTimeMinutes != selectedGame.maxPlayTimeMinutes">
                          {{ selectedGame.minPlayTimeMinutes }}'-{{ selectedGame.maxPlayTimeMinutes }}'
                        </span>
                        <span class="stat-value"
                              *ngIf="selectedGame.minPlayTimeMinutes == selectedGame.maxPlayTimeMinutes">
                          {{ selectedGame.minPlayTimeMinutes }}'
                        </span>
                        <span class="stat-label" i18n>Playing Time</span>
                    </div>
                    <div class="stat-container column center align-center">
                        <span class="stat-value">{{ selectedGame.minAge }}+</span>
                        <span class="stat-label" i18n>Age</span>
                    </div>
                </div>
                <div class="row center align-center" style="margin-top: 16px">
                    <button mat-button style="margin-right: 16px" (click)="back()" i18n>Back</button>
                    <button mat-flat-button color="accent"
                            style="margin-right: 16px"
                            (click)="select(selectedGame)"
                            i18n>This one!</button>
                    <div class="next-button-container row space-between align-center" style="margin-left: 16px">
                        <a mat-flat-button class="next-button row center align-center" (click)="nextGame()" i18n>Next</a>
                        <div class="more-button row center align-center">
                            <mat-icon [matMenuTriggerFor]="menu">arrow_drop_down</mat-icon>
                        </div>
                        <mat-menu #menu="matMenu">
                            <div class="column">
                                <a mat-button
                                   (click)="skipGame(false)"
                                   i18n>Suggest it less often</a>
                                <mat-divider></mat-divider>
                                <a mat-button
                                   color="warn"
                                   (click)="skipGame(true)"
                                   i18n>Never suggest it again</a>
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="column center align-center flex" *ngIf="loading" style="height: 100%">
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
            <div class="column center align-center flex" *ngIf="done" style="height: 100%">
                <h2 *ngIf="games.length" i18n>You have reached the end of the selected collections</h2>
                <h2 *ngIf="!games.length" i18n>The selected collections have no games for this number of players</h2>
                <div class="row center align-center" style="margin-top: 16px">
                    <button mat-button style="margin-right: 16px" (click)="back()" i18n>Back</button>
                </div>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row start">
            <button mat-button mat-dialog-close i18n>Close</button>
        </div>
    `,
    styles: [`

        .image-container {
            width: 250px;
            height: 250px;
            margin-bottom: 16px;
            border-radius: 4px;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);

            .game-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .game-name {
            font-size: 1.4rem;
            font-weight: 700;
            margin-top: 8px;
            margin-bottom: 16px;
            color: var(--mat-app-text-color);
            text-align: center;
        }

        .game-stats {

            .stat-container {
                margin-right: 10px;
                margin-left: 10px;

                .stat-value {
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: var(--mat-app-text-color);

                    .best {
                        margin-left: 5px;
                        font-size: 0.9rem;
                        color: var(--secondary-text-color);

                        .number {
                            font-size: 1rem;
                            color: #749873;
                        }

                        .mismatch {
                            color: #a5885c;
                        }

                    }
                }

                .stat-label {
                    font-size: 0.9rem;
                    color: var(--secondary-text-color);
                }
            }
        }

        .next-button-container {
            height: 36px;
            //padding-left: 16px;
            //padding-right: 16px;
            background-color: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;

            .next-button {
                width: 100%;
                height: 100%;
                color: #000000;
                background-color: #f5f5f5;
                border: none;
                cursor: pointer;
            }

            .more-button {
                width: 36px;
                min-width: 36px;
                max-width: 36px;
                color: #000000;
                border-left: 1px solid #000000;
                cursor: pointer;
            }
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        FormsModule, CommonModule,
        MatDialogModule, MatButtonModule, MatSelectModule, MatChipsModule, MatIconModule, MatTooltipModule, MatProgressSpinnerModule, MatDividerModule, MatMenuModule,
        GameHelperListComponent, PlayersSelectionFormComponent,
    ]
})
export class ChooseRandomGameDialogComponent {

    users: GamePlayerResponse[] = []

    playerCount = 1
    playerCounts = [
        2, 3, 4, 5, 6, 7
    ]

    length = 0
    lengths = [
        {value: 0, label: $localize`Any`},
        {value: 15, label: `~15'`},
        {value: 45, label: `~30'`},
        {value: 90, label: `~1h`},
        {value: 150, label: `~2h`}
    ]

    weight = 0
    weights = [
        {value: 0, label: $localize`Any`},
        {value: 1, label: $localize`Light`},
        {value: 2, label: $localize`Medium Light`},
        {value: 3, label: $localize`Medium`},
        {value: 4, label: $localize`Medium Heavy`},
        {value: 5, label: $localize`Heavy`}
    ]

    collectionsToCheck: GamePlayerResponse[] = []

    loading = false
    games: BoardGameResponse[] = []
    selectedGameIndex = -1
    selectedGame?: BoardGameResponse
    done = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {players: GamePlayerResponse[]},
        public dialogRef: MatDialogRef<ChooseRandomGameDialogComponent>,
        private userGameService: UserGameService,
        private dialog: MatDialog
    ) {
        this.playerCount = data.players.length
        this.length = localStorage.getItem('chooseRandomGameDialog.length') ? parseInt(localStorage.getItem('chooseRandomGameDialog.length')!) : 0
        this.weight = localStorage.getItem('chooseRandomGameDialog.weight') ? parseInt(localStorage.getItem('chooseRandomGameDialog.weight')!) : 0
        this.users = data.players.filter(p => p.userId != undefined)
        this.collectionsToCheck = [this.users[0]]
    }

    start() {
        if (this.collectionsToCheck.length == 0) {
            return
        }
        this.games = []
        this.selectedGameIndex = -1
        this.done = false
        this.loading = true
        this.userGameService.suggestGames(
            this.playerCount,
            this.collectionsToCheck.map(u => u.userId!),
            this.length,
            this.weight
        ).subscribe(games => {
            this.loading = false
            this.games.push(...games.list)
            if (this.games.length > 0) {
                this.nextGame()
            } else {
                this.done = true
            }
        })
    }

    back() {
        this.selectedGame = undefined
        this.done = false
        this.loading = false
    }

    selectPlayerCount(event: any) {
        this.playerCount = event.source.value
    }

    selectLength(event: any) {
        this.length = event.source.value
        localStorage.setItem('chooseRandomGameDialog.length', this.length.toString())
    }

    selectWeight(event: any) {
        this.weight = event.source.value
        localStorage.setItem('chooseRandomGameDialog.weight', this.weight.toString())
    }

    nextGame() {
        this.selectedGameIndex++
        if (this.selectedGameIndex >= this.games.length) {
            this.done = true
            this.selectedGame = undefined
            return
        }
        this.selectedGame = this.games[this.selectedGameIndex]
    }

    select(game: BoardGameResponse) {
        this.dialogRef.close(game)
    }

    skipGame(neverSuggest: boolean) {
        if (neverSuggest) {
            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: $localize`Never suggest ${this.selectedGame?.name} again`,
                    question: $localize`Are you sure you want this game to never be suggested again? If you play it again, it will come back to the suggestions.`
                }
            }).afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.userGameService.rejectGameSuggestion(this.selectedGame!.id, neverSuggest).subscribe()
                    this.nextGame()
                }
            })
        } else {
            this.userGameService.rejectGameSuggestion(this.selectedGame!.id, neverSuggest).subscribe()
            this.nextGame()
        }
    }
}
