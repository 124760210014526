import {Injectable} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogService} from "./ui/dialog.service";
import {
    BoardGameBasicResponse,
    GamePlayerResponse, GamePlayResponse,
    LocationResponse,
    MeetupResponse,
    PlannedPlayResponse, UserGameAnnotations,
    UserPlayerResponse
} from "../model/responses";
import {from, Observable, switchMap} from "rxjs";
import {PlayersSelectionDialogComponent} from "../play/players-selection-dialog.component";
import {EditUserPlayerDialogComponent} from "../user/player/edit-user-player-dialog.component";
import {PlayerOrderDialogComponent} from "../play/player-order-dialog.component";
import {LocationListDialogComponent} from "../location/location-list-dialog.component";
import {MeetupEditDialogComponent} from "../meetups/meetup-edit-dialog.component";
import {PlannedPlayEditDialogComponent} from "../meetups/planned-play-edit-dialog.component";
import {CreatePlayDialogComponent} from "../play/create-play-dialog.component";
import {NewsDialogComponent} from "../shared/news-dialog.component";
import {Visibility} from "../model/visibility";
import {ConfirmDialogComponent} from "../shared/confirm-dialog.component";
import {GroupEditDialogComponent} from "../groups/group-edit-dialog.component";
import {GroupResponse} from "../model/group-dtos";

@Injectable()
export class SharedDialogsService {

    constructor(
        private dialog: MatDialog,
        private dialogService: DialogService,
    ) {
    }

    openCreatePlay(mode: 'log' | 'play', plannedPlay?: PlannedPlayResponse): Observable<GamePlayResponse> {
        let dialogConfig: MatDialogConfig = {
            data: {
                mode: mode,
                plannedPlay: plannedPlay
            },
            disableClose: true,
            minWidth: '550px',
            height: '70%'
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(CreatePlayDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    selectPlayers(players: GamePlayerResponse[], onlyFriends: boolean = false): Observable<GamePlayerResponse[]> {
        let dialogConfig: MatDialogConfig = {
            data: {
                players: players,
                onlyFriends: onlyFriends,
            },
            minWidth: '300px',
            maxWidth: '600px',
            minHeight: '90vh',
            maxHeight: '90vh',
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(PlayersSelectionDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    editUserPlayer(player: GamePlayerResponse): Observable<UserPlayerResponse> {
        if (!player.userPlayerId) {
            throw new Error('Player must have a userPlayerId to edit')
        }
        const dialogConfig: MatDialogConfig = {
            data: {player: player},
            minWidth: '300px',
            maxWidth: '600px',
            minHeight: '90vh',
            maxHeight: '90vh',
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(EditUserPlayerDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    setPlayerOrder(players: GamePlayerResponse[]): Observable<GamePlayerResponse[]> {
        let dialogConfig: MatDialogConfig = {
            data: {
                players: players,
            }
        }
        const dialog = this.dialog.open(PlayerOrderDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    openLocations(canSelect: boolean, defaultVisibility?: Visibility): Observable<LocationResponse> {
        let dialogConfig: MatDialogConfig = {
            minWidth: '50%',
            data: {
                canSelect: canSelect,
                defaultVisibility: defaultVisibility
            }
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(LocationListDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    openMeetupEdit(meetup?: MeetupResponse): Observable<any> {
        let dialogConfig: MatDialogConfig = {
            data: {
                meetup: meetup,
            },
            minWidth: '550px',
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(MeetupEditDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    openPlannedPlayEdit(plannedPlay?: PlannedPlayResponse, meetup?: MeetupResponse): Observable<any> {
        let dialogConfig: MatDialogConfig = {
            data: {
                plannedPlay: plannedPlay,
                meetup: meetup,
            },
            minWidth: '550px',
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(PlannedPlayEditDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    openGroupEdit(group?: GroupResponse): Observable<any> {
        let dialogConfig: MatDialogConfig = {
            data: {
                group: group,
            },
            minWidth: '550px',
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(GroupEditDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    openNewsDialog() {
        let dialogConfig: MatDialogConfig = {
            minWidth: '550px',
            height: '85%'
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(NewsDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed()
    }

    openGameTagsDialog(game: BoardGameBasicResponse, annotations: UserGameAnnotations): Observable<string[]> {
        let dialogConfig: MatDialogConfig = {
            data: {
                game: game,
                annotations: annotations
            },
            minWidth: '550px',
            height: '85%'
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        return from(import('../game/game-tags-dialog.component')).pipe(
            switchMap(({ GameTagsDialogComponent }) => {
                const dialog = this.dialog.open(GameTagsDialogComponent, dialogConfig);
                this.dialogService.onDialogOpened(dialog);
                return dialog.afterClosed();
            })
        );
    }

    openCancelDialog(): Observable<boolean> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: $localize`Cancel`,
                question: $localize`Are you sure you want to cancel? All unsaved changes will be lost.`
            }
        }).afterClosed()
    }
}
