import {Component, Inject} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef, MatDialogTitle
} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {ShareService} from "../service/ui/share.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Share Image</h1>
            <div class="column align-center" *ngIf="displayUrl">
                <img [src]="displayUrl" alt="Shared Image" class="shared-image">
                <div class="row space-around">
                    <button mat-flat-button color="primary" (click)="share()" style="margin: 8px 4px">
                        <mat-icon>share</mat-icon>
                        <span i18n>Share</span>
                    </button>
                    <button mat-flat-button color="primary" (click)="download()" style="margin: 8px 4px">
                        <mat-icon>download</mat-icon>
                        <span i18n>Download</span>
                    </button>
                </div>
            </div>
            <div *ngIf="!displayUrl" class="empty-container column center align-center">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
        <div mat-dialog-actions class="row space-between">
            <button mat-button mat-dialog-close i18n>Close</button>
        </div>
    `,
    styles: [`
        .shared-image {
            //transition: transform 0.3s ease;
            max-width: 100%;
            max-height: 500px;
            //cursor: grab;
            //user-select: none;
            object-fit: contain;
        }
        .empty-container {
            height: 300px;
            width: 300px;
            margin: 16px;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        MatDialogContent,
        MatDialogTitle,
        MatProgressSpinner
    ]
})
export class ImageShareDialogComponent {

    imageUrl = `${environment.apiUrl}/render/${this.data.uri}`;
    blob?: Blob;
    displayUrl?: string;

    constructor(
        public dialogRef: MatDialogRef<ImageShareDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, uri: string },
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        this.http.get(this.imageUrl, {responseType: 'blob'}).subscribe(blob => {
            this.blob = blob;
            this.displayUrl = URL.createObjectURL(blob);
        })
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    download() {
        if ((window as any).Android && (window as any).Android.downloadImage) {
            (window as any).Android.downloadImage(this.imageUrl)
        } else {
            const fileName = this.imageUrl.substring(this.imageUrl.lastIndexOf('/') + 1);
            if (this.displayUrl) {
                const a = document.createElement('a');
                a.href = this.displayUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }
    }

    share() {
        //this.shareService.shareImage(this.data.title, this.imageUrl)
        if ((window as any).Android && (window as any).Android.shareImage) {
            (window as any).Android.shareImage(this.data.title, this.imageUrl)
        } else if (navigator.share) {
            const end = this.imageUrl.includes('?') ? this.imageUrl.lastIndexOf('?') : this.imageUrl.length;
            const fileName = this.imageUrl.substring(this.imageUrl.lastIndexOf('/') + 1, end) + '.jpg';
            if (this.displayUrl && this.blob) {
                // convert blob to file
                const file = new File([this.blob], fileName, {type: this.blob.type});
                navigator.share({
                    title: this.data.title,
                    files: [file]
                }).catch(console.error)
            }
        } else {
            // Copy link
            navigator.clipboard.writeText(this.imageUrl).then(() => {
                this.snackBar.open($localize`Link copied to clipboard`, undefined, {duration: 2000})
            })
        }
    }
}
