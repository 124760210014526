import {Component, Inject} from "@angular/core";
import {LocationResponse} from "../model/responses";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BreakpointObserver} from "@angular/cdk/layout";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {NgIf, NgFor} from "@angular/common";
import {HeaderComponent} from "../shared/header.component";
import {SharedDialogsService} from "../service/shared-dialogs.service";
import {Visibility} from "../model/visibility";
import {
    MAT_DIALOG_DATA,
    MatDialogActions, MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {AuthedUserService} from "../service/authed-user.service";
import {AvatarComponent} from "../user/avatar.component";
import {ImageComponent} from "../shared/image.component";
import {GroupService} from "../service/api/group.service";
import {GroupJoinPolicy, GroupRequest, GroupResponse} from "../model/group-dtos";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Group Setup</h1>
        <mat-dialog-content>
            <form class="add-content-form column" *ngIf="!saving">
                <mat-form-field style="width: 100%" subscriptSizing="dynamic">
                    <mat-label i18n>Group Name</mat-label>
                    <input matInput [(ngModel)]="name" name="title" required>
                </mat-form-field>
                <div class="row center" style="margin: 8px 0">
                    <cb-image width="150" height="150" [imageUrl]="imageUrl" editable
                              (imageChange)="imageFile = $event">
                    </cb-image>
                </div>
                <mat-form-field style="width: 100%">
                    <mat-label i18n>Group Description</mat-label>
                    <textarea matInput cdkTextareaAutosize [(ngModel)]="description" name="description" required></textarea>
                </mat-form-field>
                <div class="row center align-center" style="margin-bottom: 16px">
                    <button mat-stroked-button *ngIf="location" (click)="openLocationsDialog()"
                            style="width: 100%; height: 48px; background-color: var(--mat-app-background-color-variant)">
                        <mat-icon>location_on</mat-icon>
                        <span>{{ location.name }}</span>
                    </button>
                    <button mat-icon-button class="small-icon-button" *ngIf="location" (click)="location = undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-stroked-button *ngIf="!location" (click)="openLocationsDialog()"
                            style="width: 100%; height: 48px; background-color: var(--mat-app-background-color-variant)">
                        <mat-icon>not_listed_location</mat-icon>
                        <span style="color: var(--secondary-text-color); font-size: 16px" i18n>Set Location</span>
                        <span>*</span>
                    </button>
                </div>
                <div class="row space-between align-center">
                    <mat-form-field appearance="fill" class="fill">
                        <mat-label i18n>Visibility</mat-label>
                        <mat-select [(value)]="visibility">
                            <mat-option *ngFor="let settingType of visibilities"
                                        [value]="settingType.type">{{ settingType.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row space-between align-center">
                    <mat-form-field appearance="fill" class="fill">
                        <mat-label i18n>Join Policy</mat-label>
                        <mat-select [(value)]="joinPolicy">
                            <mat-option *ngFor="let settingType of joinPolicies"
                                        [value]="settingType.type">{{ settingType.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div class="saving column center align-center" *ngIf="saving">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-flat-button color="primary" (click)="save()"
                    [disabled]="!name || !location || saving"
                    i18n>Save
            </button>
        </div>
    `,
    styles: [`
        .add-content-form {
            margin: 8px;
        }

        .section-separation {
            margin-bottom: 16px;
        }

        .saving {
            margin-top: 32px;
        }

        .write-post-attachment-buttons {
            margin-top: 10px;
            margin-right: 10px;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [HeaderComponent, NgIf, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatIconModule, MatDatepickerModule, MatProgressSpinnerModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, AvatarComponent, ImageComponent]
})
export class GroupEditDialogComponent {

    name?: string
    description?: string
    location?: LocationResponse
    visibility: Visibility = Visibility.PUBLIC
    joinPolicy: GroupJoinPolicy = GroupJoinPolicy.OPEN

    imageUrl?: string
    imageFile?: Blob

    saving = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            group?: GroupResponse
        },
        private dialogRef: MatDialogRef<GroupEditDialogComponent>,
        private groupService: GroupService,
        private snackBar: MatSnackBar,
        public breakpointObserver: BreakpointObserver,
        private sharedDialogsService: SharedDialogsService,
        private authedUserService: AuthedUserService,
    ) {
        this.authedUserService.assertAuthedUser()

        this.name = data.group?.name
        this.description = data.group?.description
        this.location = data.group?.location
        this.visibility = data.group?.visibility || Visibility.PUBLIC
        this.imageUrl = data.group?.imageUrl
    }

    save() {
        if (!this.name || !this.description || !this.location || this.saving) {
            return
        }

        const request: GroupRequest = {
            name: this.name,
            description: this.description,
            locationId: this.location?.id,
            visibility: this.visibility,
            joinPolicy: this.joinPolicy
        }

        this.saving = true
        if (!this.data.group) {
            this.groupService.createGroup(request, this.imageFile).subscribe({
                next: response => {
                    this.saving = false
                    this.dialogRef.close(response.id)
                },
                error: error => {
                    this.saving = false
                    this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
                }
            })
        } else {
            this.groupService.updateGroup(this.data.group!.id, request, this.imageFile).subscribe({
                next: response => {
                    this.saving = false
                    this.dialogRef.close()
                },
                error: error => {
                    this.saving = false
                    this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
                }
            })
        }
    }

    openLocationsDialog() {
        this.sharedDialogsService.openLocations(true, this.visibility).subscribe(location => {
            if (location) {
                this.location = location
            }
        })
    }

    visibilities = [
        {
            type: Visibility.PUBLIC,
            label: $localize`Public`,
        },
        {
            type: Visibility.PROTECTED,
            label: $localize`Only Members`,
        }
    ]

    joinPolicies = [
        {
            type: GroupJoinPolicy.OPEN,
            label: $localize`Open`,
        },
        {
            type: GroupJoinPolicy.REQUEST,
            label: $localize`Requires Approval`,
        },
        {
            type: GroupJoinPolicy.INVITE_ONLY,
            label: $localize`Invite Only`,
        }
    ]
}
