import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {AvatarComponent} from "../user/avatar.component";
import {MatChipAvatar} from "@angular/material/chips";
import {MatListOption} from "@angular/material/list";
import {GamePlayerResponse} from "../model/responses";
import {CdkDrag, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Player Order</h1>
        <mat-dialog-content>
            <p *ngIf="resortedPlayers.length <= 2" i18n>Randomize the player order.</p>
            <p *ngIf="resortedPlayers.length > 2" i18n>Randomize the player order by rotating the first player or shuffling the players.</p>
            <div class="row space-between align-center" style="margin-bottom: 16px">
                <button *ngIf="resortedPlayers.length > 2" mat-flat-button color="primary" (click)="rotatePlayers()" i18n>Rotate</button>
                <button mat-flat-button color="primary" (click)="shufflePlayers()" i18n>Shuffle</button>
            </div>
            <p i18n>You can also drag and drop players to change their order manually.</p>
            <div cdkDropList class="column" (cdkDropListDropped)="dropPlayer($event)">
                @for (player of resortedPlayers; track player.userId || player.userPlayerId || player.name; let i = $index) {
                    <div cdkDrag class="player-text row left align-center" style="padding: 5px">
                        <div class="row end" style="width: 20px; margin-right: 4px">
                            <span *ngIf="!animating" class="player-number">#{{ i + 1 }}</span>
                        </div>
                        <cb-avatar matChipAvatar [size]="34" style="min-width: 34px"
                                   [name]="player.name" [avatarUrl]="player.avatarUrl" [isBot]="player.isBot">
                        </cb-avatar>
                        <span class="player-name" style="margin-left: 10px">{{ player.name }}</span>
                        <span *ngIf="player.username" class="player-username">&#64;{{ player.username }}</span>
                    </div>
                }
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-flat-button color="primary" (click)="save()" i18n>Save</button>
        </div>
    `,
    styles: [`
        .player-text {
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .player-name {
                font-size: 1rem;
                font-weight: 700;
            }

            .player-username {
                margin-left: 6px;
                font-size: 1rem;
                font-weight: 400;
                color: var(--secondary-text-color);
                min-width: 70px;
            }
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, FormsModule,
        MatDialogModule, MatProgressSpinnerModule, MatButtonModule, MatInputModule, AvatarComponent, MatChipAvatar, MatListOption, CdkDropList, CdkDrag
    ]
})
export class PlayerOrderDialogComponent {

    resortedPlayers: GamePlayerResponse[]
    animating = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { players: GamePlayerResponse[] },
        private dialogRef: MatDialogRef<PlayerOrderDialogComponent>
    ) {
        this.resortedPlayers = [...data.players];
    }

    save() {
        this.dialogRef.close(this.resortedPlayers)
    }

    setPlayerPositions() {
        this.resortedPlayers.forEach((player, index) => player.startPosition = index + 1)
    }

    dropPlayer(event: any) {
        moveItemInArray(this.resortedPlayers, event.previousIndex, event.currentIndex);
        this.setPlayerPositions()
    }

    rotatePlayers() {
        this.animating = true
        let initialIndex = Math.floor(Math.random() * this.resortedPlayers.length)
        // Cycle the array so the first player is the random player
        this.resortedPlayers = [...this.resortedPlayers.slice(initialIndex), ...this.resortedPlayers.slice(0, initialIndex)]
        this.rotationAnimation(25)
    }

    rotationAnimation(delay: number) {
        setTimeout(() => {
            const index = this.resortedPlayers.length - 1
            this.resortedPlayers = [...this.resortedPlayers.slice(index), ...this.resortedPlayers.slice(0, index)]
            if (delay > 500) {
                this.setPlayerPositions()
                this.animating = false
                return
            }
            this.rotationAnimation(delay * 1.25)
        }, delay)
    }

    setRandomPlayerOrder() {
        // Use the Fisher-Yates (Knuth) shuffle algorithm
        for (let i = this.resortedPlayers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this.resortedPlayers[i], this.resortedPlayers[j]] = [this.resortedPlayers[j], this.resortedPlayers[i]];
        }
    }

    shufflePlayers() {
        if (this.resortedPlayers.length <= 2) {
            this.rotatePlayers()
            return
        }
        this.animating = true
        this.setRandomPlayerOrder()
        this.shuffleAnimation(25)
    }

    shuffleAnimation(delay: number) {
        setTimeout(() => {
            this.setRandomPlayerOrder()
            if (delay > 500) {
                this.setPlayerPositions()
                this.animating = false
                return
            }
            this.shuffleAnimation(delay * 1.25)
        }, delay)
    }
}

