

function hashCode(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        hash = (hash * 33) ^ str.charCodeAt(i);
    }
    // Ensure positive integer
    return hash >>> 0;
}

const colors = [
    '#8AB4F8',
    '#F28B82',
    '#FDD663',
    '#81C995',
    '#FF8BCB',
    '#C58AF9',
    '#78D9EC',
    //'#FCAD70'
]

export function getAvatarColor(name: string): string {
    const hash = hashCode(name)
    const colorIndex = hash % colors.length
    let colorIndex2 = colorIndex
    let index = 0
    while (colorIndex2 == colorIndex) {
        let hash2 = hashCode(index++ + name)
        colorIndex2 = hash2 % colors.length
    }
    return `linear-gradient(45deg, ${colors[colorIndex]}, ${colors[colorIndex2]})`
}

export const playerColors = [
    '#FFFFFF',
    '#DD3333',
    '#DD6633',
    '#DD9933',
    '#e8d923',
    '#91dd33',
    '#33DD33',
    '#338833',
    '#33DD88',
    '#33DDDD',
    '#3388DD',
    '#3333FF',
    '#6666FF',
    '#9933FF',
    '#DD33DD',
    '#DD88DD',
    '#775533',
    '#8A8A8A',
    '#000000',
]

export const playerColorPicks = [1, 6, 11, 4, 18, 14, 2, 0, 3, 5, 7, 8, 9, 10, 12, 13, 15, 16, 17]
