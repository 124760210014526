import {
    booleanAttribute,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {FormControl, Validators, ReactiveFormsModule} from "@angular/forms";
import {BoardGameBasicResponse} from "../model/responses";
import {GameSearch} from "../search/game-search";
import {MatOptionModule} from "@angular/material/core";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {GameExpansionsFormComponent} from "./game-expansions-form.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {SearchService} from "../service/api/search.service";

@Component({
    selector: 'cb-game-search-form',
    template: `
        <div class="fill column" *ngIf="selectedGame && !error">
            <div class="post-game-banner row space-between align-center">
                <div class="post-game-banner-image-container"
                     [style.background-image]="'url(' + selectedGame.image.url + ')'"></div>
                <span class="post-game-banner-text">{{ selectedGame.name }}</span>
                <button mat-icon-button class="menu-button"
                        (click)="selectedGame = undefined; selectedGameChange.emit(undefined); gameControl.reset()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <cb-game-expansions-form *ngIf="selectedGame && chooseExpansions" [game]="selectedGame"
                                     [selectedExpansions]="selectedExpansions"
                                     (selectedExpansionsChange)="selectedExpansionsChange.emit($event)"
            ></cb-game-expansions-form>
        </div>
        <div class="game-search-form" *ngIf="!selectedGame || error">
            <mat-form-field class="log-full-width" appearance="fill">
                <mat-label i18n>Game</mat-label>
                <input type="text"
                       placeholder=""
                       i18n-aria-label
                       aria-label="Game"
                       matInput
                       (input)="filterGames($event)"
                       [formControl]="gameControl"
                       [matAutocomplete]="game"
                       matAutocompletePosition="below">
                <mat-error *ngIf="gameControl.hasError('required')" i18n>
                    Game is required
                </mat-error>
                <mat-error *ngIf="gameControl.hasError('error')">
                    {{ error }}
                </mat-error>
                <mat-autocomplete #game="matAutocomplete">
                    <mat-option *ngIf="extraOptionText" (click)="$event.preventDefault(); extraOptionClick.emit()"
                                [value]="undefined"
                                style="background-color: var(--mat-app-background-color-variant)">
                        <mat-icon *ngIf="extraOptionIcon">{{extraOptionIcon}}</mat-icon>
                        {{ extraOptionText }}
                    </mat-option>
                    <mat-option *ngFor="let option of filteredGames" [value]="option.name"
                                (onSelectionChange)="selectGame(option, $event)">
                        <div class="row start align-center">
                            <img width="40" height="40" [src]="option.image.thumbnailUrl" [alt]="option.name"/>
                            <span style="padding: 5px">{{ option.name }} ({{ option.yearPublished }})</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    `,
    styles: [`
        .post-game-banner {
            width: calc(100% - 16px);
            height: 56px;
            padding-left: 16px;
            margin-bottom: 16px;
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            text-decoration: none;

            .post-game-banner-image-container {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-size: cover;
                background-position: center;
                //filter: brightness(0.8) saturate(0.5);
                filter: brightness(0.6);
                //opacity: 0.4;
            }

            .post-game-banner-text {
                font-size: 1rem;
                font-weight: 700;
                color: #ffffff;
                text-shadow: 0 0 10px black;
                position: relative;
                text-decoration: none;
            }
        }

        .game-search-form {
            width: 100%;
        }

        .log-full-width {
            width: 100%;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule,
        MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatOptionModule,
        GameExpansionsFormComponent
    ]
})
export class GameSearchFormComponent extends GameSearch implements OnInit, OnChanges {

    @Input()
    selectedGame: BoardGameBasicResponse | undefined
    @Input()
    override preloadedGames: BoardGameBasicResponse[] = []
    @Input()
    selectedExpansions: BoardGameBasicResponse[]
    @Input({transform: booleanAttribute})
    optional: boolean = false
    @Input({transform: booleanAttribute})
    onlyBaseGames: boolean = false
    @Input({transform: booleanAttribute})
    chooseExpansions: boolean = false
    @Input()
    extraOptionText: string | undefined
    @Input()
    extraOptionIcon: string | undefined
    @Input()
    error: string | undefined
    @Input({transform: booleanAttribute})
    required: boolean = true

    @Output()
    selectedGameChange = new EventEmitter<BoardGameBasicResponse | undefined>()
    @Output()
    selectedExpansionsChange = new EventEmitter<BoardGameBasicResponse[]>()
    @Output()
    extraOptionClick = new EventEmitter<void>()

    gameControl = new FormControl('', [Validators.required]);

    constructor(
        searchService: SearchService
    ) {
        super(searchService)
    }

    ngOnInit() {
        this.performFilter('', this.onlyBaseGames)
        if (!this.required) {
            this.gameControl = new FormControl('')
        }
        if (this.optional) {
            this.gameControl.clearValidators()
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectedGame) {
            this.gameControl.setValue(this.selectedGame.name)
        }
        if (changes['preloadedGames'] && changes['preloadedGames'].currentValue != changes['preloadedGames'].previousValue) {
            this.performFilter(this.currentFilter, this.onlyBaseGames)
        }
        this.checkErrors()
    }

    checkErrors(): void {
        if (this.optional) {
            return
        }
        if (this.error) {
            this.gameControl.setErrors({error: this.error})
        }
        // Mark the form as touched if the game is selected, but only after checking for the error
        if (this.selectedGame || this.error) {
            this.gameControl.markAllAsTouched()
            setTimeout(() => this.checkErrors(), 100)
        }
    }

    filterGames(event: any) {
        const filter = event.target.value
        this.performFilter(filter, this.onlyBaseGames)
    }

    selectGame(game: BoardGameBasicResponse, event: any) {
        // Trigger only at select
        if (event.source.selected) {
            this.selectedGame = game
            this.selectedGameChange.emit(game)
        }
    }
}
