import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {NavigationService} from "src/app/service/ui/navigation.service";
import {GamePlayBasicResponse, GamePlayResponse} from "../model/responses";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgIf, NgFor} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {shortenDurationString} from "./edit-duration-dialog.component";
import {GamePlayPlayerListComponent} from "./game-play-player-list.component";
import {PlayDatePipe} from "./play-date.pipe";

@Component({
    selector: 'cb-game-play-list',
    template: `
        <div class="column center align-center list" *ngIf="plays.length > 0">
            <a class="row space-between align-center play"
               [href]="'p/' + play.id"
               (click)="$event.preventDefault(); navigationService.navigate(['p', play.id])"
               *ngFor="let play of plays; let playIndex = index">
                <div class="row space-between fill">
                    <img class="game-img" [src]="play.game.image.previewUrl" [alt]="play.game.name"/>
                    <div class="column start align-start fill play-body">
                        <div class="row space-between align-center fill play-header">
                            <div class="row start play-header-left"
                                 [class.count-header]="play.count != 1 && !play.duration"
                                 [class.duration-header]="play.count == 1 && play.duration"
                                 [class.both-header]="play.count != 1 && play.duration">
                                <span class="play-header-game-name">{{ play.game.name }}</span>
                                <span class="play-header-separator">·</span>
                                <span class="play-header-date">{{ play.date | playDate }}</span>
                            </div>
                            <span *ngIf="play.count != 1" class="play-header-date" style="margin-left: 8px">x{{play.count}}</span>
                            <div *ngIf="play.duration" class="row align-center play-header-date" style="margin-left: 8px">
                                <mat-icon>timer</mat-icon>
                                <span style="margin-left: 4px">{{ shortenDurationString(play.duration) }}</span>
                            </div>
                        </div>
                        <div class="row space-between align-center fill">
                            <cb-game-play-player-list [players]="play.players"></cb-game-play-player-list>
                            <div class="column align-center" style="margin-right: 8px;"
                                 *ngIf="play.topScore" i18n-matTooltip
                                 matTooltip="Winning Score">
                                <img *ngIf="play.anyWinner" class="crown" src="assets/crown.svg" alt="crown"/>
                                <span class="play-top-score">{{ play.topScore }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="no-content-message-container column center align-center" *ngIf="plays.length == 0">
            <span class="no-content-message" i18n>No plays</span>
        </div>
    `,
    styles: [`
        .list {
            width: 100%;

            .play {
                position: relative;
                width: calc(100% - 16px);
                margin: 4px 0;
                padding: 8px;
                color: var(--mat-app-text-color);
                text-decoration: none;
                border-radius: 8px;
                //background-color: var(--mat-app-background-color);
                border: 1px solid color-mix(in srgb, var(--mat-app-text-color) 20%, transparent);

                .game-img {
                    width: 68px;
                    height: 68px;
                    object-fit: cover;
                    margin-right: 8px;
                    border-radius: 4px;
                }

                .play-body {
                    position: relative;
                    max-width: calc(100% - 68px - 8px);

                    .play-header {
                        position: relative;
                        max-width: 100%;
                        font-size: 0.938rem;

                        .play-header-left {
                            position: relative;
                            max-width: 100%;

                            .play-header-game-name {
                                font-weight: 700;
                                //color: var(--accent-color);
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .play-header-separator {
                                margin-left: 4px;
                                margin-right: 4px;
                                color: var(--secondary-text-color);
                            }

                            .play-header-date {
                                color: var(--secondary-text-color);
                                white-space: nowrap;
                            }
                        }

                        .count-header {
                            max-width: calc(100% - 32px);
                        }
                        .duration-header {
                            max-width: calc(100% - 90px);
                        }
                        .both-header {
                            max-width: calc(100% - 32px - 90px);
                        }
                    }

                    .play-top-score {
                        font-size: 1.2rem;
                        font-weight: 700;
                    }

                    .crown {
                        width: 16px;
                        height: 16px;
                        margin-bottom: -2px;
                        object-fit: cover;
                    }
                }
            }
        }

        .no-content-message-container {
            margin: 96px 16px;

            .no-content-message {
                font-size: 2rem;
                font-weight: 700;
                text-align: center;
                color: var(--secondary-text-color);
            }
        }
    `],
    standalone: true,
    imports: [NgIf, NgFor, GamePlayPlayerListComponent, MatTooltipModule, PlayDatePipe, MatIcon, PlayDatePipe, PlayDatePipe]
})
export class GamePlayListComponent implements OnInit, OnChanges {

    @Input()
    plays: GamePlayBasicResponse[] = []

    constructor(
        public navigationService: NavigationService
    ) {
    }

    ngOnInit(): void {
        this.updatePlays()
    }

    ngOnChanges(): void {
        this.updatePlays()
    }

    updatePlays() {
        this.plays.forEach(play => {
            play.anyWinner = play.players.some(p => p.isWinner)
            play.topScore = this.getTopScore(play)
        })
    }

    getTopScore(play: GamePlayBasicResponse): number | undefined {
        const scores = play.players
            .filter(p => p.isWinner && p.score)
            .map(player => player.score!)
        const maxScore = Math.max(...scores)
        if (maxScore > 0) {
            return maxScore
        }
        return undefined
    }

    protected readonly shortenDurationString = shortenDurationString;
}
