export type Language = {
    code: string
    name: string
}

export const languages: Language[] = [
    {code: 'en', name: $localize`English`},
    {code: 'ca', name: $localize`Catalan`},
    {code: 'de', name: $localize`German`},
    {code: 'es', name: $localize`Spanish`},
    {code: 'fr', name: $localize`French`},
    {code: 'it', name: $localize`Italian`},
    {code: 'hu', name: $localize`Hungarian`},
    {code: 'nl', name: $localize`Dutch`},
    {code: 'no', name: $localize`Norwegian`},
    {code: 'pl', name: $localize`Polish`},
    {code: 'pt', name: $localize`Portuguese`},
    {code: 'ro', name: $localize`Romanian`},
    {code: 'ja', name: $localize`Japanese`},
    {code: 'zh', name: $localize`Chinese`},
    {code: 'ru', name: $localize`Russian`},
    {code: 'ko', name: $localize`Korean`},
    {code: 'tr', name: $localize`Turkish`},
    {code: 'sv', name: $localize`Swedish`},
    {code: 'da', name: $localize`Danish`},
    {code: 'fi', name: $localize`Finnish`},
    {code: 'cs', name: $localize`Czech`},
    {code: 'ar', name: $localize`Arabic`},
    {code: 'bg', name: $localize`Bulgarian`},

]

export function getLanguage(code: string): Language {
    return languages.find(l => l.code == code) || languages[0]
}
